import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import AbstractSubmissionForm from "../components/part/abstractSubmissionForm"

const abstractSubmissionPage = () => (
  <Layout>
    <SEO title="Submission" />
    <Banner title="New Abstract Submission"></Banner>
    <AbstractSubmissionForm></AbstractSubmissionForm>
  </Layout>
)

export default abstractSubmissionPage
