import React from "react"
import { Modal, Button } from "antd"
import { connect } from "react-redux"
import { navigate } from "gatsby"
// import { Files } from "../../redux/api"

class AbstractSubmissionForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      author: "",
      affiliationAuthor1: "",
      affiliationAuthor2: "",
      affiliationAuthor3: "",
      affiliationAuthor4: "",
      affiliationAuthor5: "",
      category: "",
      background: "",
      methods: "",
      result: "",
      conclusion: "",
      keywords1: "",
      keywords2: "",
      keywords3: "",
      keywords4: "",
      keywords5: "",
      errors: {},
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handlerValidation() {
    let errors = {}
    let formIsValid = true
    let errorEmpty = "*Cannot be empty"
    let errorEmptySelection = "*You have to select one"
    let title = this.state.title
    let author = this.state.author
    let affiliationAuthor1 = this.state.affiliationAuthor1
    let affiliationAuthor2 = this.state.affiliationAuthor2
    let affiliationAuthor3 = this.state.affiliationAuthor3
    let affiliationAuthor4 = this.state.affiliationAuthor4
    let affiliationAuthor5 = this.state.affiliationAuthor5
    let category = this.state.category
    let background = this.state.background
    let methods = this.state.methods
    let result = this.state.result
    let conclusion = this.state.conclusion
    let keywords1 = this.state.keywords1

    // TITLE
    if (title.trim() === "") {
      formIsValid = false
      errors["title"] = errorEmpty
    }

    // AUTHOR
    if (typeof author !== "undefined") {
      if (!author.match(/^[a-zA-Z,.'" ]+$/)) {
        formIsValid = false
        errors["author"] = "*Characters not allowed"
      }
    }

    if (author.trim() === "") {
      formIsValid = false
      errors["author"] = errorEmpty
    }

    // AFFILIATION AUTHOR
    ;[
      ["affiliationAuthor1", affiliationAuthor1],
      ["affiliationAuthor2", affiliationAuthor2],
      ["affiliationAuthor3", affiliationAuthor3],
      ["affiliationAuthor4", affiliationAuthor4],
      ["affiliationAuthor5", affiliationAuthor5],
    ].forEach(aff => {
      if (typeof aff[1] === "undefined") return
      if (aff[1].match(/^[a-zA-Z0-9,.-_'" ]*$/)) return
      formIsValid = false
      errors[aff[0]] = "*Characters not allowed"
    })

    // CATEGORY
    if (category.trim() === "") {
      formIsValid = false
      errors["category"] = errorEmptySelection
    }

    // BACKGROUND
    if (background.trim() === "") {
      formIsValid = false
      errors["background"] = errorEmpty
    }

    // METHOD
    if (methods.trim() === "") {
      formIsValid = false
      errors["methods"] = errorEmpty
    }

    // RESULT
    if (result.trim() === "") {
      formIsValid = false
      errors["result"] = errorEmpty
    }

    // CONCLUSION
    if (conclusion.trim() === "") {
      formIsValid = false
      errors["conclusion"] = errorEmpty
    }

    // KEYWORD-1
    if (keywords1.trim() === "") {
      formIsValid = false
      errors["keywords1"] = errorEmpty
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  state = {
    modal1Visible: false,
    modal2Visible: false,
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible })
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible })
  }

  handleInputChange(event) {
    event.preventDefault()
    const target = event.target
    this.setState({ [target.name]: target.value })
  }

  async handleSubmit(event) {
    event.preventDefault()
    if (this.handlerValidation()) {
      const {
        title,
        author,
        affiliationAuthor1,
        affiliationAuthor2,
        affiliationAuthor3,
        affiliationAuthor4,
        affiliationAuthor5,
        category,
        background,
        methods,
        result,
        conclusion,
        keywords1,
        keywords2,
        keywords3,
        keywords4,
        keywords5,
      } = this.state
      const author_affiliation = [
        affiliationAuthor1,
        affiliationAuthor2,
        affiliationAuthor3,
        affiliationAuthor4,
        affiliationAuthor5,
      ].join("| ")
      const keywords = [
        keywords1,
        keywords2,
        keywords3,
        keywords4,
        keywords5,
      ].join(" ")
      const payload = {
        title,
        author,
        author_affiliation,
        category,
        background,
        methods,
        result,
        conclusion,
        keywords,
      }
      if (payload);
      // const res = await Files.submitting(payload)
      // if (!res) return
      navigate("/profile")
    }
  }

  async handleDiscard(event) {
    event.preventDefault()
    navigate("/profile")
  }

  render() {
    const category = this.state.category
    const valueOral = "oral"
    const valuePoster = "poster"
    const isOral = category === valueOral ? `active` : ``
    const isPoster = category === valuePoster ? `active` : ``

    return (
      <div className="content-wrap">
        <div className="container">
          <div className="row pt-4">
            <div className="col-lg-8">
              <div className="form-widget">
                <div className="form-result" />
                <div className="col-12 form-group mb-4">
                  <label htmlFor="title">Title</label>
                  <textarea
                    className="form-control required"
                    name="title"
                    placeholder="Enter your Abstract Title"
                    rows={3}
                    style={{ minHeight: "50px" }}
                    value={this.state.title}
                    onChange={this.handleInputChange}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["title"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="author">Author (comma separated)</label>
                  <input
                    type="text"
                    name="author"
                    className="form-control required"
                    placeholder="John Doe, Mary Jane, Robin Hood"
                    value={this.state.author}
                    onChange={this.handleInputChange}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["author"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="affiliation-author">
                    Author's Affiliation (Optional)
                  </label>
                  <ol className="ml-3 mb-0">
                    {[
                      "affiliationAuthor1",
                      "affiliationAuthor2",
                      "affiliationAuthor3",
                      "affiliationAuthor4",
                      "affiliationAuthor5",
                    ].map((aff, i) => (
                      <li key={i} className="pr-2">
                        <input
                          type="text"
                          name={aff}
                          className="form-control mb-2 ml-2"
                          placeholder={`Affiliation ${i + 1}`}
                          value={this.state[aff]}
                          onChange={this.handleInputChange}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors[aff]}
                        </span>
                      </li>
                    ))}
                  </ol>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="category-type" className="mb-3">
                    Category
                  </label>
                  <br />
                  <div
                    className="btn-group btn-group-toggle flex-wrap"
                    data-toggle="buttons"
                    style={{ width: "100%" }}
                  >
                    <button
                      name="category"
                      value={valueOral}
                      onClick={this.handleInputChange}
                      className={
                        "btn btn-outline-secondary px-3 font-weight-semibold ls0 nott " +
                        isOral
                      }
                    >
                      Oral
                    </button>
                    <button
                      name="category"
                      value={valuePoster}
                      onClick={this.handleInputChange}
                      className={
                        "btn btn-outline-secondary px-3 font-weight-semibold ls0 nott " +
                        isPoster
                      }
                    >
                      Poster
                    </button>
                  </div>
                  <span style={{ color: "red" }}>
                    {this.state.errors["category"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="background">Background</label>
                  <textarea
                    className="form-control"
                    name="background"
                    rows={5}
                    style={{ minHeight: "10px" }}
                    placeholder="Write your abstact background"
                    value={this.state.background}
                    onChange={this.handleInputChange}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["background"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="methods">Method</label>
                  <textarea
                    className="form-control"
                    name="methods"
                    rows={5}
                    style={{ minHeight: "100px" }}
                    placeholder="Write your abstact method"
                    value={this.state.methods}
                    onChange={this.handleInputChange}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["methods"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="result">Result</label>
                  <textarea
                    className="form-control"
                    name="result"
                    rows={7}
                    style={{ minHeight: "150px" }}
                    placeholder="Write your abstact result"
                    value={this.state.result}
                    onChange={this.handleInputChange}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["result"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="conclusion">Conclusion</label>
                  <textarea
                    className="form-control"
                    name="conclusion"
                    rows={5}
                    style={{ minHeight: "100px" }}
                    placeholder="Write your abstact conclusion"
                    value={this.state.conclusion}
                    onChange={this.handleInputChange}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["conclusion"]}
                  </span>
                </div>
                <div className="col-12 form-group mb-4">
                  <label htmlFor="keywords">Keywords</label>
                  <ol className="ml-3 mb-0">
                    <li className="pr-2">
                      <input
                        type="text"
                        name="keywords1"
                        className="form-control mb-2 ml-2 required"
                        placeholder="1st Keywords"
                        value={this.state.keywords1}
                        onChange={this.handleInputChange}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["keyword1"]}
                      </span>
                    </li>
                    <li className="pr-2">
                      <input
                        type="text"
                        name="keywords2"
                        className="form-control mb-2 ml-2"
                        placeholder="2nd Keywords"
                        value={this.state.keywords2}
                        onChange={this.handleInputChange}
                      />
                    </li>
                    <li className="pr-2">
                      <input
                        type="text"
                        name="keywords3"
                        className="form-control mb-2 ml-2"
                        placeholder="3rd Keywords"
                        value={this.state.keywords3}
                        onChange={this.handleInputChange}
                      />
                    </li>
                    <li className="pr-2">
                      <input
                        type="text"
                        name="keywords4"
                        className="form-control mb-2 ml-2"
                        placeholder="4th Keywords"
                        value={this.state.keywords4}
                        onChange={this.handleInputChange}
                      />
                    </li>
                    <li className="pr-2">
                      <input
                        type="text"
                        name="keywords5"
                        className="form-control mb-2 ml-2"
                        placeholder="5th Keywords"
                        value={this.state.keywords5}
                        onChange={this.handleInputChange}
                      />
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 line line-sm mb-4 pt-4">
            <div className="button-right">
              <Button
                className="button button-submit button-3d button-circle float-right gradient-grey-orange"
                onClick={() => this.setModal1Visible(true)}
                rel={1}
              >
                Submit Abstract
              </Button>

              <Modal
                title="Submit Abstract"
                visible={this.state.modal1Visible}
                onOk={this.handleOk}
                onCancel={() => this.setModal1Visible(false)}
                footer={[
                  <Button
                    key="back"
                    onClick={() => this.setModal1Visible(false)}
                  >
                    Return
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <p className="modal-size mb-0">
                  are you sure with your submission?
                </p>
              </Modal>

              <Button
                className="button button-circle button-border button-cancel button-white float-right"
                onClick={() => this.setModal2Visible(true)}
                rel={2}
              >
                Cancel
              </Button>

              <Modal
                title="Discard"
                visible={this.state.modal2Visible}
                onOk={this.handleOk}
                onCancel={() => this.setModal2Visible(false)}
                footer={[
                  <Button
                    key="discard"
                    type="primary"
                    onClick={this.handleDiscard}
                  >
                    Discard
                  </Button>,
                  <Button
                    key="back"
                    onClick={() => this.setModal2Visible(false)}
                  >
                    Back
                  </Button>,
                ]}
              >
                <p className="modal-size mb-0">
                  Are you sure to discard this changes?
                </p>
              </Modal>
            </div>
          </div>
          <div className="clear" />
        </div>
      </div>
    )
  }
}

export default connect()(AbstractSubmissionForm)
